import React from "react";
import { Link } from "react-router-dom";
import jaatis from "../assets/photographerGallery/jaatis1.jpg";
import ralli from "../assets/photographerGallery/rallitool.jpg";
import projektor from "../assets/photographerGallery/projektor.jpg";

const NewOpportunities = () => {
  return (
    <div className="p-5">
      <h3>Uued võimalused mängustuudios</h3>
      <div className="row">
        <div className="col-lg-4 col-md-12 d-flex justify-content-center pt-5 py-3 ">
          <div
            className="card"
            style={{
              maxWidth: "25rem",
              borderColor: "#f4d4f7",
              borderWidth: "5px",
            }}
          >
            <Link to={"/pehmejaatis"}>
              <img
                src={jaatis}
                className="card-img-top"
                alt="Pehmejäätisemasin ja toidukaunistused lauapeal"
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title">Pehmejäätisemasina rent</h5>
              <p className="card-text">
                Pehme jäätis koos kaunistustega nautimiseks nii lastele kui ka
                täiskasvanutele.
              </p>
              <Link to={"/pehmejaatis"}>
                <span className="black-link">Rohkem infot</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-12 d-flex justify-content-center pt-5 py-3">
          <div
            className="card"
            style={{
              maxWidth: "25rem",
              borderColor: "#f4d4f7",
              borderWidth: "5px",
            }}
          >
            <Link to={"/tutvustus"}>
              <img
                src={ralli}
                className="card-img-top"
                alt="Rallitool koos rooli ja pedaalidega. Seina peal on televiisor, kus on näha videomängu"
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title">Rallitool</h5>
              <p className="card-text">
                Rallisimulaatoril saavad ennast proovile panna kõik, kellele
                meeldivad autod ja kiirus.
              </p>
              <p>
                Täpsem info on leitav mängutoa{" "}
                <Link to={"/tutvustus"}>
                  <span className="black-link">tutvustuse lehel</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 d-flex justify-content-center pt-5 py-3">
          <div
            className="card"
            style={{
              maxWidth: "25rem",
              borderColor: "#f4d4f7",
              borderWidth: "5px",
            }}
          >
            <Link to={"/tutvustus"}>
              <img
                src={projektor}
                className="card-img-top"
                alt="Projektoriekraan, millel on Just Dance mäng"
              />
            </Link>
            <div className="card-body">
              <h5 className="card-title">Projektor</h5>
              <p className="card-text">
                Võimalik vaadata rahulikult filmi või panna end koos sõpradega
                proovile tantsumängus Just Dance.
              </p>
              <p>
                Täpsem info on leitav mängutoa{" "}
                <Link to={"/tutvustus"}>
                  <span className="black-link">tutvustuse lehel</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOpportunities;
